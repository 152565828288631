

@keyframes bounceIn {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
#fadeOut {
  animation: fadeOut 0.5s ease-in-out;
}


#bounceIn {
  animation: bounceIn 0.5s ease-in-out;
  animation-delay: 0.3s;
}
#bounceOut {
  animation: bounceOut 0.5s ease-in-out;
}

/* Modal container */
.ModalContainer-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.ModalContainer-card {
  padding: auto;
  width: auto;
  height: auto;  
  position: fixed;

  background-color:  rgba(58, 58, 58, 0.651);
  box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.5);
  animation: bounceIn 0.4s ease-in-out;
}

.ModalContainer-Image {
  width: 41%;
  height: auto;
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.ModalContainer-Right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ModalContainer-CloseButton {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 20px;
  color: #bb1212;
  cursor: pointer;
}
.ModalContainer-Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0 auto;
  padding: auto;
}
.ModalContainer-Form{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0 auto;
  padding: auto;
  margin-bottom: 20px;
}
.ModalContainer-Form-Group {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  position: relative;
  justify-content: absolute;
  align-items: center;
  width: auto;
  height: auto;
}


.ModalContainer-Form-Input{
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0 auto;
  padding: auto;
}
@media screen and (max-width: 1200px) {
  .ModalContainer-Form-Input {
    width: 80%;
    height: 100%;
  }
  .ModalContainer-Form-Group {
    width: 100%;
    height: 100%;
    transform: scale(0.93);
  }
}

.ModalContainer-Logo {
  width: 30%;
  margin-top: -50px;
  margin-bottom: 30px;
  border-radius: 30%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.ModalContainer-Buttons {
  display: flex;
  flex-direction: column;
}
.ModalContainer-Buttons button {
  width: 100%;
  margin: .5rem;
  padding: 16px 30px;
  border: none;
  color: #fff;

}
.ModalContainer-ButtonYes {
  font-size: 18px;
  border: none;
  border-radius: 10px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #6a7dfe;
  background: -webkit-linear-gradient(to left, #2179fd, #2155ff);
  background: -o-linear-gradient(to left, #2179fd, #2155ff);
  background: -moz-linear-gradient(to left, #2179fd, #2155ff);
  background: linear-gradient(to left, #2179fd, #2155ff);
}
.ModalContainer-ButtonNo {
  font-size: 18px;
  border: none;
  border-radius: 10px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #6a7dfe;
  background: -webkit-linear-gradient(to left, #2179fd, #2155ff);
  background: -o-linear-gradient(to left, #2179fd, #2155ff);
  background: -moz-linear-gradient(to left, #2179fd, #2155ff);
  background: linear-gradient(to left, #8b0f0fa6, #2a2a2b);
}

@media screen and (max-width: 1200px) {
  .ModalContainer-card {
    flex-direction: column;  
    width: 80%;
    height: auto;
    border-radius: 0;
  }
  .ModalContainer-Image {
    width: 70%;
    height: 40%;
    border-top-right-radius: 5%;
    border-bottom-left-radius: 10%;
  }
  .ModalContainer-Right {
    width: 100%;
    height: 100%;
  } 
}

@media screen and (max-width: 768px) {
  .ModalContainer-card {
    flex-direction: column;
    width: 80%;
    height: auto;
    border-radius: 0;
  }
  .ModalContainer-Image {
    width: 70%;
    height: 40%;
    border-top-right-radius: 5%;
    border-bottom-left-radius: 10%;
  }
  .ModalContainer-Right {
    width: 100%;
    height: 100%;
  } 
}