.RETOserversInfo {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    width: 100%;
  }
  
.tableScroll {
  overflow-y: scroll;
  height: 250px;
}

  table {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ffffff21;
    border-radius: 20px;
    overflow: hidden;
    
  }
  
  th {
    border: 1px solid #ffffff1a;
    text-align: left;
    padding: 13px;
    font-size: 19px;
    width: 100%;
  }

  td {
    border: 1px solid #ffffff36;
    text-align: left;
    padding: 13px;
    font-size: 17px;
    width: 100%;
    
  }
  

  
  form {
    display: flex;
    gap: 5px;
  }
  
  form td:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  form * {
    font-size: 18px;
  }